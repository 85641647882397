<template>
  <div>
    <v-card
      :class="!renderInCourseDesign ? 'exam-card' : 'rounded-xl border-1-2 border-solid border-primary'"
      class="mx-auto"
      max-width="550"

    >
      <div class="pa-0 ma-0">
        <v-row class="pa-0 exam-title-grade-dropdown-section">
          <v-col cols="7" class="pa-0 ma-0">
            <v-card-title
              class="pt-0 mt-0 pb-0 mb-0 ml-0 pl-0 text-truncate mk-exam-title custom-font-family"
            >
              {{ exam.title }}
            </v-card-title>

            <!-- Backend does not support this feature -->
            <v-card-subtitle
              class="pt-1 mt-1 ml-0 pl-0 mk-exam-upload-date custom-font-family"
            >
              {{ !$language ? "Upload Date" : "প্রকাশের তারিখ" }} :
              {{
                !$language
                  ? exam.created_at.split(" ")[0]
                  : new Date(exam.created_at).toLocaleString("bn-BD", {
                      dateStyle: "medium",
                    })
              }}
            </v-card-subtitle>
          </v-col>
          <v-col
            v-if="renderInCourseDesign"
            cols="5"
            class="pa-0 ma-0 mk-exam-duration"
          >
            <v-card-subtitle
              v-if="!$language"
              class="custom-font-family pa-0 ma-0"
            >
              <img
                src="../assets/E_Duration.svg"
                alt=""
                height="31"
                width="31"
                class="mr-2"
              />
              Duration: {{ exam.duration }} Min
            </v-card-subtitle>
            <v-card-subtitle v-else class="custom-font-family pa-0 ma-0">
              <img
                src="../assets/E_Duration.svg"
                alt=""
                height="31"
                width="31"
                class="mr-2"
              />
              সময়কাল: {{ exam.duration }} মিনিট
            </v-card-subtitle>
          </v-col>
          <v-col
            v-if="!renderInCourseDesign"
            cols="4"
            class="pa-0 ma-0 d-flex justify-end"
          >
            <v-btn
              depressed
              plain
              class="mk-exam-btn-grade custom-font-family"
              :to="{ name: 'exam-batches', params: { examId: exam.id } }"
              style="margin-right: 1rem"
            >
              <img src="../assets/Grade.svg" width="25" height="26" alt="" />
              {{ !$language ? "Grade" : "গ্রেড" }}
            </v-btn>
          </v-col>
          <v-col
            v-if="!renderInCourseDesign"
            cols="1"
            class="pa-0 ma-0 exam-dropdown-section"
          >
            <div class="text-center d">
              <v-menu offset-y left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="black" icon v-bind="attrs" v-on="on">
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-if="isOwner"
                    class="exam-card-menu-list-item clickable"
                    :to="{ name: 'exam-edit', params: { examId: exam.id } }"
                    :disabled="!!exam.d_lock"
                  >
                    <v-list-item-title class="subtitle-2 custom-font-family">
                      <v-icon>fas fa-list</v-icon
                      >{{
                        !$language ? "Edit Exam" : "পরীক্ষা এডিট করুন"
                      }}</v-list-item-title
                    >
                  </v-list-item>

                  <v-list-item
                    v-if="isOwner"
                    class="exam-card-menu-list-item clickable"
                    @click="$emit('delete', exam)"
                  >
                    <v-list-item-title class="subtitle-2 custom-font-family">
                      <v-icon>fas fa-list</v-icon
                      >{{
                        !$language ? "Delete exam" : "পরীক্ষা ডিলিট করুন"
                      }}</v-list-item-title
                    >
                  </v-list-item>

                  <v-list-item
                    class="exam-card-menu-list-item clickable"
                    :to="{
                      name: 'exam-create',
                      query: { duplicate_from: exam.id },
                    }"
                  >
                    <v-list-item-title class="subtitle-2 custom-font-family">
                      <v-icon>fas fa-list</v-icon
                      >{{
                        !$language
                          ? "Duplicate exam"
                          : "পরীক্ষার প্রতিলিপি করুন"
                      }}</v-list-item-title
                    >
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </v-col>
        </v-row>

        <v-row
          v-if="!renderInCourseDesign"
          class="px-0 mb-0 py-0 mk-exam-batches-total-mark-section"
        >
          <v-col cols="7" class="pa-0 ma-0 mk-exam-share-with-batches">
            <v-card-subtitle
              v-if="!$language"
              class="custom-font-family pa-0 ma-0"
            >
              <img
                src="../assets/Share_N_Batch.svg"
                alt=""
                height="31"
                width="31"
                class="mr-2"
              />
              With {{ sharesCount }} Batches
            </v-card-subtitle>
            <v-card-subtitle v-else class="custom-font-family pa-0 ma-0">
              <img
                src="../assets/Share_N_Batch.svg"
                alt=""
                height="31"
                width="31"
                class="mr-2"
              />
              {{ sharesCount }} টি ব্যাচের সাথে শেয়ার করা হয়েছে
            </v-card-subtitle>
          </v-col>
          <!-- <v-col cols="4" class="pa-0 ma-0 mk-exam-total-mark">
            <v-card-subtitle class="custom-font-family pa-0 ma-0">
              <img
                src="../assets/Total_Mark.svg"
                alt=""
                height="31"
                width="31"
                class="mr-2"
              />
              Total Mark: {{ exam.points }}
            </v-card-subtitle>
          </v-col> -->
          <v-col cols="5" class="pa-0 ma-0 mk-exam-duration">
            <v-card-subtitle
              v-if="!$language"
              class="custom-font-family pa-0 ma-0"
            >
              <img
                src="../assets/E_Duration.svg"
                alt=""
                height="31"
                width="31"
                class="mr-2"
              />
              Duration: {{ exam.duration }} Min
            </v-card-subtitle>
            <v-card-subtitle v-else class="custom-font-family pa-0 ma-0">
              <img
                src="../assets/E_Duration.svg"
                alt=""
                height="31"
                width="31"
                class="mr-2"
              />
              সময়কাল: {{ exam.duration }} মিনিট
            </v-card-subtitle>
          </v-col>
        </v-row>
      </div>

      <v-row class="pa-0 mk-share-detail-section">
        <v-col cols="5" class="d-flex justify-left ma-0 pa-0 mk-detail">
          <v-btn
            depressed
            tile
            plain
            class="custom-font-family"
            @click="
              $router.push({
                name: 'exam-details',
                params: { examId: exam.id },
              })
            "
          >
            {{ !$language ? "Details" : "বিস্তারিত" }}
          </v-btn>
        </v-col>
        <v-col
          v-if="!renderInCourseDesign"
          cols="7"
          class="d-flex ma-0 pa-0 mk-share"
        >
          <v-btn
            depressed
            tile
            plain
            class="custom-font-family"
            @click="$emit('share')"
          >
            <img
              src="../assets/Share_Btn.svg"
              width="25"
              height="25"
              alt=""
              class="mr-2"
            />
            {{ !$language ? "Share" : "শেয়ার করুন" }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "MExamListItem",
  props: {
    exam: {
      type: Object,
      required: true,
    },
    sharesCount: {
      type: Number,
      default: 0,
    },
    renderInCourseDesign: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {};
  },

  computed: {
    // share_batches_names() {
    //   return this.exam.shared_batches.join(", ");
    // },
    currentOrganization() {
      return this.$store.state.organization.currentOrganization;
    },
    // shares_count() {
    //   return typeof this.$store.state.exam.examShares[this.exam.id] == "object"
    //     ? Object.keys(this.$store.state.exam.examShares[this.exam.id]).length
    //     : 0;
    // },
    isOwner() {
      return this.$store.state.user.user.access[
        this.currentOrganization.partner_code
      ].includes("owner");
    },
  },
  methods: {},
};
</script>

<style scoped lang="scss">
@import "#ecf/exam/styles/MExamListItem.scss";
</style>
