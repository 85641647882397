var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-card",
        {
          staticClass: "mx-auto",
          class: !_vm.renderInCourseDesign
            ? "exam-card"
            : "rounded-xl border-1-2 border-solid border-primary",
          attrs: { "max-width": "550" },
        },
        [
          _c(
            "div",
            { staticClass: "pa-0 ma-0" },
            [
              _c(
                "v-row",
                { staticClass: "pa-0 exam-title-grade-dropdown-section" },
                [
                  _c(
                    "v-col",
                    { staticClass: "pa-0 ma-0", attrs: { cols: "7" } },
                    [
                      _c(
                        "v-card-title",
                        {
                          staticClass:
                            "pt-0 mt-0 pb-0 mb-0 ml-0 pl-0 text-truncate mk-exam-title custom-font-family",
                        },
                        [_vm._v(" " + _vm._s(_vm.exam.title) + " ")]
                      ),
                      _c(
                        "v-card-subtitle",
                        {
                          staticClass:
                            "pt-1 mt-1 ml-0 pl-0 mk-exam-upload-date custom-font-family",
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                !_vm.$language
                                  ? "Upload Date"
                                  : "প্রকাশের তারিখ"
                              ) +
                              " : " +
                              _vm._s(
                                !_vm.$language
                                  ? _vm.exam.created_at.split(" ")[0]
                                  : new Date(
                                      _vm.exam.created_at
                                    ).toLocaleString("bn-BD", {
                                      dateStyle: "medium",
                                    })
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm.renderInCourseDesign
                    ? _c(
                        "v-col",
                        {
                          staticClass: "pa-0 ma-0 mk-exam-duration",
                          attrs: { cols: "5" },
                        },
                        [
                          !_vm.$language
                            ? _c(
                                "v-card-subtitle",
                                { staticClass: "custom-font-family pa-0 ma-0" },
                                [
                                  _c("img", {
                                    staticClass: "mr-2",
                                    attrs: {
                                      src: require("../assets/E_Duration.svg"),
                                      alt: "",
                                      height: "31",
                                      width: "31",
                                    },
                                  }),
                                  _vm._v(
                                    " Duration: " +
                                      _vm._s(_vm.exam.duration) +
                                      " Min "
                                  ),
                                ]
                              )
                            : _c(
                                "v-card-subtitle",
                                { staticClass: "custom-font-family pa-0 ma-0" },
                                [
                                  _c("img", {
                                    staticClass: "mr-2",
                                    attrs: {
                                      src: require("../assets/E_Duration.svg"),
                                      alt: "",
                                      height: "31",
                                      width: "31",
                                    },
                                  }),
                                  _vm._v(
                                    " সময়কাল: " +
                                      _vm._s(_vm.exam.duration) +
                                      " মিনিট "
                                  ),
                                ]
                              ),
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.renderInCourseDesign
                    ? _c(
                        "v-col",
                        {
                          staticClass: "pa-0 ma-0 d-flex justify-end",
                          attrs: { cols: "4" },
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass:
                                "mk-exam-btn-grade custom-font-family",
                              staticStyle: { "margin-right": "1rem" },
                              attrs: {
                                depressed: "",
                                plain: "",
                                to: {
                                  name: "exam-batches",
                                  params: { examId: _vm.exam.id },
                                },
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: require("../assets/Grade.svg"),
                                  width: "25",
                                  height: "26",
                                  alt: "",
                                },
                              }),
                              _vm._v(
                                " " +
                                  _vm._s(!_vm.$language ? "Grade" : "গ্রেড") +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.renderInCourseDesign
                    ? _c(
                        "v-col",
                        {
                          staticClass: "pa-0 ma-0 exam-dropdown-section",
                          attrs: { cols: "1" },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "text-center d" },
                            [
                              _c(
                                "v-menu",
                                {
                                  attrs: { "offset-y": "", left: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on, attrs }) {
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    attrs: {
                                                      color: "black",
                                                      icon: "",
                                                    },
                                                  },
                                                  "v-btn",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _c("v-icon", [
                                                  _vm._v("mdi-dots-vertical"),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    4082850938
                                  ),
                                },
                                [
                                  _c(
                                    "v-list",
                                    [
                                      _vm.isOwner
                                        ? _c(
                                            "v-list-item",
                                            {
                                              staticClass:
                                                "exam-card-menu-list-item clickable",
                                              attrs: {
                                                to: {
                                                  name: "exam-edit",
                                                  params: {
                                                    examId: _vm.exam.id,
                                                  },
                                                },
                                                disabled: !!_vm.exam.d_lock,
                                              },
                                            },
                                            [
                                              _c(
                                                "v-list-item-title",
                                                {
                                                  staticClass:
                                                    "subtitle-2 custom-font-family",
                                                },
                                                [
                                                  _c("v-icon", [
                                                    _vm._v("fas fa-list"),
                                                  ]),
                                                  _vm._v(
                                                    _vm._s(
                                                      !_vm.$language
                                                        ? "Edit Exam"
                                                        : "পরীক্ষা এডিট করুন"
                                                    )
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.isOwner
                                        ? _c(
                                            "v-list-item",
                                            {
                                              staticClass:
                                                "exam-card-menu-list-item clickable",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.$emit(
                                                    "delete",
                                                    _vm.exam
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "v-list-item-title",
                                                {
                                                  staticClass:
                                                    "subtitle-2 custom-font-family",
                                                },
                                                [
                                                  _c("v-icon", [
                                                    _vm._v("fas fa-list"),
                                                  ]),
                                                  _vm._v(
                                                    _vm._s(
                                                      !_vm.$language
                                                        ? "Delete exam"
                                                        : "পরীক্ষা ডিলিট করুন"
                                                    )
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c(
                                        "v-list-item",
                                        {
                                          staticClass:
                                            "exam-card-menu-list-item clickable",
                                          attrs: {
                                            to: {
                                              name: "exam-create",
                                              query: {
                                                duplicate_from: _vm.exam.id,
                                              },
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "v-list-item-title",
                                            {
                                              staticClass:
                                                "subtitle-2 custom-font-family",
                                            },
                                            [
                                              _c("v-icon", [
                                                _vm._v("fas fa-list"),
                                              ]),
                                              _vm._v(
                                                _vm._s(
                                                  !_vm.$language
                                                    ? "Duplicate exam"
                                                    : "পরীক্ষার প্রতিলিপি করুন"
                                                )
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
              !_vm.renderInCourseDesign
                ? _c(
                    "v-row",
                    {
                      staticClass:
                        "px-0 mb-0 py-0 mk-exam-batches-total-mark-section",
                    },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "pa-0 ma-0 mk-exam-share-with-batches",
                          attrs: { cols: "7" },
                        },
                        [
                          !_vm.$language
                            ? _c(
                                "v-card-subtitle",
                                { staticClass: "custom-font-family pa-0 ma-0" },
                                [
                                  _c("img", {
                                    staticClass: "mr-2",
                                    attrs: {
                                      src: require("../assets/Share_N_Batch.svg"),
                                      alt: "",
                                      height: "31",
                                      width: "31",
                                    },
                                  }),
                                  _vm._v(
                                    " With " +
                                      _vm._s(_vm.sharesCount) +
                                      " Batches "
                                  ),
                                ]
                              )
                            : _c(
                                "v-card-subtitle",
                                { staticClass: "custom-font-family pa-0 ma-0" },
                                [
                                  _c("img", {
                                    staticClass: "mr-2",
                                    attrs: {
                                      src: require("../assets/Share_N_Batch.svg"),
                                      alt: "",
                                      height: "31",
                                      width: "31",
                                    },
                                  }),
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.sharesCount) +
                                      " টি ব্যাচের সাথে শেয়ার করা হয়েছে "
                                  ),
                                ]
                              ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "pa-0 ma-0 mk-exam-duration",
                          attrs: { cols: "5" },
                        },
                        [
                          !_vm.$language
                            ? _c(
                                "v-card-subtitle",
                                { staticClass: "custom-font-family pa-0 ma-0" },
                                [
                                  _c("img", {
                                    staticClass: "mr-2",
                                    attrs: {
                                      src: require("../assets/E_Duration.svg"),
                                      alt: "",
                                      height: "31",
                                      width: "31",
                                    },
                                  }),
                                  _vm._v(
                                    " Duration: " +
                                      _vm._s(_vm.exam.duration) +
                                      " Min "
                                  ),
                                ]
                              )
                            : _c(
                                "v-card-subtitle",
                                { staticClass: "custom-font-family pa-0 ma-0" },
                                [
                                  _c("img", {
                                    staticClass: "mr-2",
                                    attrs: {
                                      src: require("../assets/E_Duration.svg"),
                                      alt: "",
                                      height: "31",
                                      width: "31",
                                    },
                                  }),
                                  _vm._v(
                                    " সময়কাল: " +
                                      _vm._s(_vm.exam.duration) +
                                      " মিনিট "
                                  ),
                                ]
                              ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "pa-0 mk-share-detail-section" },
            [
              _c(
                "v-col",
                {
                  staticClass: "d-flex justify-left ma-0 pa-0 mk-detail",
                  attrs: { cols: "5" },
                },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "custom-font-family",
                      attrs: { depressed: "", tile: "", plain: "" },
                      on: {
                        click: function ($event) {
                          return _vm.$router.push({
                            name: "exam-details",
                            params: { examId: _vm.exam.id },
                          })
                        },
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(!_vm.$language ? "Details" : "বিস্তারিত") +
                          " "
                      ),
                    ]
                  ),
                ],
                1
              ),
              !_vm.renderInCourseDesign
                ? _c(
                    "v-col",
                    {
                      staticClass: "d-flex ma-0 pa-0 mk-share",
                      attrs: { cols: "7" },
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "custom-font-family",
                          attrs: { depressed: "", tile: "", plain: "" },
                          on: {
                            click: function ($event) {
                              return _vm.$emit("share")
                            },
                          },
                        },
                        [
                          _c("img", {
                            staticClass: "mr-2",
                            attrs: {
                              src: require("../assets/Share_Btn.svg"),
                              width: "25",
                              height: "25",
                              alt: "",
                            },
                          }),
                          _vm._v(
                            " " +
                              _vm._s(!_vm.$language ? "Share" : "শেয়ার করুন") +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }